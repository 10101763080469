import React from 'react';
import config from '../../config';
import { richText } from '../../util/richText';
import { IconClose, IconCheckmark } from '../../components'; 

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

import css from './ListingPage.module.css';

const customFieldValueLabel = (field, key) => {
  if(Array.isArray(key)){
    let options = [];
    key.map(k => {
      const option = field.options.find(f => f.key === k) || k;
      const optionLabel = option ? <span className={css.pill}>{option.label ? option.label : option}</span> : null;
      if (optionLabel) 
        options.push(optionLabel)
    })
    return options
  }
  else{
    const option = field.options.find(f => f.key === key);
    return option ? option.label : null; 
  }
  
};

const SectionCustomFieldsMaybe = props => {
  const { publicData } = props;
  const stemSubjects = config.custom.customFields.subjectsTab.filter(f => f.key === 'stem')
  const socialSubjects = config.custom.customFields.subjectsTab.filter(f => f.key === 'social')
  const artsSubjects = config.custom.customFields.subjectsTab.filter(f => f.key === 'arts')
  const customDescriptionfields = config.custom.customFields.descriptionTab.filter(f => f.visible)
  const schoolField = config.custom.customFields.descriptionTab.filter(f => f.key === 'what-is-the-name-of-the-school-where-you-teach-or-most-recently-taught')[0];
  const policies = publicData && publicData.rules;
  return customDescriptionfields ?
    <div className={css.customFieldsContainer}>
      <div className={css.customFieldsGroup}>
        <div className={css.customField}>
          <h2 className={css.customFieldTitle}>
            {schoolField.listingViewLabel}
          </h2>
          <p className={css.customFieldValue}>
            {publicData[schoolField.key]}
          </p>
        </div>
      </div>
      <div className={css.customFieldsGroup}>
        <h2 className={css.customFieldTitle}>
          Subjects
        </h2>
        <div className={css.customField}>
          <p className={css.customDescriptionFieldTitle}>
            STEM
          </p>
          <p className={`${css.customFieldValue} ${css.checkboxGroup}`}>
            {stemSubjects.map(f => customFieldValueLabel(f, publicData[f.key]))}
          </p>
        </div>
        <div className={css.customField}>
          <p className={css.customDescriptionFieldTitle}>
            Social Sciences
          </p>
          <p className={`${css.customFieldValue} ${css.checkboxGroup}`}>
            {socialSubjects.map(f => customFieldValueLabel(f, publicData[f.key]))}
          </p>
        </div>
        <div className={css.customField}>
          <p className={css.customDescriptionFieldTitle}>
            Language Arts
          </p>
          <p className={`${css.customFieldValue} ${css.checkboxGroup}`}>
            {artsSubjects.map(f => customFieldValueLabel(f, publicData[f.key]))}
          </p>
        </div>
        
      </div>
      <div className={`${css.customFieldsGroup} ${css.customDescriptionFields}`}>
        <h2 className={css.customFieldTitle}>
          Details  
        </h2>
        {customDescriptionfields.map(f => 
          (<div className={css.customDescriptionField}>
            <p className={css.customDescriptionFieldTitle}>
              {f.listingViewLabel}
            </p>
            <p className={`${css.customDescriptionFieldValue} ${ f.type === 'checkboxGroup' ? css.checkboxGroup : null}`}>
              {
                f.type === 'checkboxGroup' ||
                (f.type === 'radio' && !f.isBoolean) ||
                f.type === 'dropdown' ?
                customFieldValueLabel(f, publicData[f.key]) :
                f.isBoolean ? 
                publicData[f.key] === 'yes' ? <IconCheckmark rootClassName={css.checkmarkIcon} /> : <IconClose rootClassName={css.closeIcon} /> :
                publicData[f.key]}
            </p>
          </div>)
        )}
      </div>
      <div className={css.customFieldsGroup}>
        <div className={css.customField}>
          <h2 className={css.customFieldTitle}>
            Policies
          </h2>
          <p className={css.customFieldValue}>
            {richText(policies, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
              longWordClass: css.longWord,
            })}
          </p>
        </div>
      </div>
    </div>
  : null;
};

export default SectionCustomFieldsMaybe;
